import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f6f007d0 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _2f28a02a = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _9c21de56 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _cf953264 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _03889fa3 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _0d0b1fee = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5964ee9a = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _4985b644 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _2724e798 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _aada5f78 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _4808f5f8 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _7dca0080 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _bd652a18 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _7e2b07fb = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _75d38b88 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _07a00e21 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _2fd2ccf2 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _f6f007d0,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _2f28a02a,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _9c21de56,
    name: "callback"
  }, {
    path: "/downloads",
    component: _cf953264,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _03889fa3,
    name: "guests-details"
  }, {
    path: "/login",
    component: _0d0b1fee,
    name: "login"
  }, {
    path: "/maintenance",
    component: _5964ee9a,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _4985b644,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _2724e798,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _aada5f78,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _4808f5f8,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _7dca0080,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _bd652a18,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _7e2b07fb,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _75d38b88,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _07a00e21,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _2fd2ccf2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
